import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./reset.css";
import Main from "./pages/main";
import About from "./pages/about";
import Story from "./pages/story";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import GlobalStyle, { themeColors } from "./utils/globalstyle";
const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
  },
  { path: "/about", element: <About /> },
  { path: "/story", element: <Story /> },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={themeColors}>
      <GlobalStyle />
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>
);
