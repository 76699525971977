import React from "react";
import aboutImage from "../../images/about-alwihda.jpg";
import styled from "styled-components";
import media from "../../utils/media";
import democracyIcon from "../../images/democracy.png";
import equalityIcon from "../../images/equality.png";
import justiceIcon from "../../images/justice.png";
import libertyIcon from "../../images/liberty.png";

const Container = styled.section`
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  gap: 10px;
  padding: 10px;
  ${media.tablet`
  width: 90%;
  margin-inline: auto;
  `}

  #about-image {
    width: 100%;
    ${media.tablet`
    height: 600px;
    object-fit: cover;
    `}
  }
  #about-title {
    text-align: center;
    color: #ed1c24;
    font-size: 4em;
    font-weight: bold;
    ${media.tablet`
    padding: 10px;
    `}
  }
  #about-description {
    font-weight: bold;
    font-size: 1.5em;
    line-height: 1.6em;
    text-align: justify;
    ${media.tablet`
    text-align: center;
    `}
  }
  ${media.tablet`
    .about-section-container {
      display: flex;
      flex-flow: row wrap;
      gap: 30px;
    }
  `}
  .about-section {
    margin-top: 10px;
    display: flex;
    flex-flow: row wrap;
    gap: 20px;
    ${media.tablet`
      flex-flow: column wrap;
      flex: 1;
      align-items: center;
      jusitfy-content: center;
    `}
    h3 {
      ${media.tablet`
      font-size: 2em;
      line-height: 2.4em;
    `}
      width: 100%;
      font-size: 1.5em;
      font-weight: bold;
      text-align: center;
    }
    img {
      height: 100px;
      width: auto;
      flex: 1;
      ${media.tablet`
      width: 50%;
      height: auto;
    `}
    }
    p {
      flex: 4;
      font-size: 1.2em;
      line-height: 1.6em;
      text-align: justify;
      ${media.tablet`
      font-size: 1.5em;
      line-height: 1.3em;
    `}
    }
  }
`;
function About() {
  return (
    <Container id="about">
      {/* <img
        id="about-image"
        src={aboutImage}
        alt="صورة تحتوي على مبادئ قائمة الوحدة الطلابية"
      /> */}
      <h2 id="about-title">الوحدة الطلابية</h2>
      <p id="about-description">
        قائمة طلابية وطنية ديمقراطية تخوض انتخابات الاتحاد الوطني لطلبة الكويت
        فرع الولايات المتحدة الأمريكية لضمان رفاهية الطالب الكويتي في أمريكا
        والدفاع عن مكتسباته وحقوقه.
      </p>
      <div className="about-section-container">
        <div className="about-section">
          <h3>الديمقراطية</h3>
          <img src={democracyIcon} alt="" />
          <p>
            نؤمن بالنظام الديمقراطي في الإدارة، وينعكس ذلك على طبيعة عملنا
            وتعاملنا مع الآخرين. فنحن نتخذ قرارات القائمة بآليات ديمقراطية فيما
            بيننا، كما أننا نعتمد رؤية انتخابية مبنية على آراء أغلبية الطلبة
            والطالبات الكويتيين في الولايات المتحدة الأمريكية.
          </p>
        </div>

        <div className="about-section">
          <h3>الحرية</h3>
          <img src={libertyIcon} alt="" />
          <p>
            نرى أن المساواة بين أفراد المجتمع في الحقوق والواجبات أمر أساسي
            للتعايش، وكذلك الحال لأي لنجاح أي منظمة. ولذلك نناهض التمييز
            والتفرقة العنصرية بسبب الرأي السياسي أو الخلفية الاجتماعية أو
            الدينية.
          </p>
        </div>
        <div className="about-section">
          <h3>المساواة</h3>
          <img src={equalityIcon} alt="" />
          <p>
            نعتقد أن الحرية كائنة لكل شخص بمحض وجوده، وأن لكل فرد الحق في أن
            يعتقد ما يراه مناسباً دون تطرف ودون تدخل الأخرين في خصوصياته. كما
            نؤمن بحرية تكوين الجمعيات والنقابات على أسس وطنية والطباعة والنشر
            دون مس الآخرين.
          </p>
        </div>
        <div className="about-section">
          <h3>العدالة</h3>
          <img src={justiceIcon} alt="" />
          <p>
            نؤمن أن العدالة في التعامل مع الآخرين صمام أمان المجتمع، وضمان لحفظ
            حقوق الأفراد ومكتسباتهم، كما أننا نتعامل فيما بيننا من هذا المنظور
            لكسب ثقة الأفراد وبناء مؤسسة فاعلة. كما أننا نعمل على الدفاع عن حقوق
            الآخرين دون النظر إلى اختلافاتنا الفكرية معهم.
          </p>
        </div>
      </div>
    </Container>
  );
}

export default About;
