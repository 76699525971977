import React from "react";
import styled from "styled-components";
import media from "../../utils/media";
import {
  academicVision,
  activistVision,
  nationalistVision,
  nuksRole,
} from "./electoral-vision";

const Section = styled.section`
  padding: 20px;
  background-color: #ed1c24;
  color: #fff;
  ${media.tablet`
    display: flex;
    flex-flow: row wrap;
    gap: 40px;
  `}
  .vision-section {
    flex: 1;
  }
  h2 {
    font-size: 2em;
    font-weight: bold;
    display: block;
    text-align: center;
    padding: 10px;
    color: #fff;
  }
  h3 {
    font-size: 1.5em;
    font-weight: bold;
    text-align: center;
    text-decoration: underline;
    padding: 10px;
  }
  ol {
    margin-inline-start: 30px;
    list-style-type: decimal;
  }
  li {
    text-align: justify;
    font-size: 1.2em;
    line-height: 1.6em;
    margin-block-end: 10px;
  }
  p {
    font-size: 1.2em;
    line-height: 1.6em;
    text-align: justify;
    margin-block-end: 20px;
  }
`;

function Vision() {
  return (
    <Section id="vision">
      <h2>الرؤية الانتخابية</h2>
      <div className="vision-section">
        <h3>الجانب الأكاديمي</h3>
        <ol>
          {academicVision.map((item , i) => (
            <li key={i}>{item}</li>
          ))}
        </ol>
      </div>
      <div className="vision-section">
        <h3>الجانب الوطني</h3>
        <ol>
          {nationalistVision.map((item , i) => (
            <li key={i}>{item}</li>
          ))}
        </ol>
      </div>
      <div className="vision-section">
        <h3>الجانب النقابي</h3>
        <p>{activistVision}</p>
      </div>
      <div className="vision-section">
        <h3>دور الاتحاد</h3>
        {nuksRole.map((par , i) => (
          <p key={i}>{par}</p>
        ))}
      </div>
    </Section>
  );
}

export default Vision;
