export const academicVision = [
  `العمل على عدم احتساب نظام ال Pathway/Bridge كسنه دراسية من ضمن فترة اللغة وعودته كما كان في السابق.`,
  `تعيين ممثلي اتحاد من المبتعثين من جهات غير التعليم العالي و انشاء حملة خاصة بهم وإرشادهم وهذه الحملة ستضم مبتعثين:
(ديوان الخدمة المدنية، ديوان المحاسبة، الديوان الأميري، الإطفاء، جامعة الكويت، الهيئة العامة للتعليم التطبيقي، الهيئة العامة لشؤون ذوي الإعاقة، مؤسسة الكويت للتقدم العلمي، الهيئة العامة للاستثمار، البنك المركزي، مبتعثي وزارة الداخلية والدفاع، وغيرهم من جهات الإبتعاث الأخرى)
`,
  `المطالبة بالسماح للطلبة بالابتعاث لدراسة فترة اللغة دون الحاجة لتقديم ما يثبت اجتيازهم اختبار تحديد مستوى اللغة بشرط استيفائهم للدرجة المطلوبة خلال أول 6 أشهر من دراسة اللغة في الولايات المتحدة الأمريكية.
`,
  ` تغيير نظام البعثات المتميزة (Merit) ليكون على تصنيف الجامعات المعترف بها دوليا وليس على معدل الالتحاق كما هو النظام الحالي.
`,
  `العمل على تفعيل نظام ال (Merit B) وتطبيق شروطه على الطلبة المستوفين لمتطلبات البعثة المتميزة الرديفة.
`,
  `تحديث قائمة الجامعات المتميزة في الولايات المتحدة الأمريكية وفقا لتصنيف عادل وواضح يعتد به للسنوات القادمة وبشكل دوري.
`,
  `توسيع دور اللجنة الأكاديمية في القيام بدراسات شاملة عن التضخم كل ٦ شهور عوضاً عن كل سنة و تقديمها للجهات المختصه.
`,
  `متابعة ملف التحاق طلبة الدراسات العليا بنظام البعثات المميزة.
`,
  `السماح في الدفع الفوري للمديونيات المستحقة على الطالب وتغيير آلية مديونيات الملحق الثقافي وتفعيل بند الإعفاء الكلي من لائحة البعثات فور إتمام الطالب درجته الأكاديمية حتى لا يتم خصم معاش الطالب و تخييره.`,
  `زيادة عدد الوحدات المسموح بها بنظام (الأونلاين) و المواد خارج الجامعة الأم الملتحق بها مراعاةً للتوجه العام للجامعات في الولايات المتحدة الأمريكية وتبينها نظام الدراسة عن بعد بشكل أوسع.
`,
  `الاعتراف بنظام الـ 4+1 ونظام ال Accelerated Master’s.
`,
  `العمل على توفير لجنة للشؤون القانونية في الملاحق الثقافية للنظر والبت في القرارات بشكل أسرع دون الحاجة للرجوع لوزارة التعليم العالي في الكويت.
`,
  `المطالبة بتحديث لائحة البعثات الخاصة في وزارة التعليم العالي وتبني بنود ومواد تختص بالطلبة الدارسين في الولايات المتحدة الأمريكية على وجه التحديد.
`,
  `المطالبة بتكفّل الوزارة والملاحق الثقافية بتكاليف اختبارات تحديد مستوى اللغة بمختلفها لطلبة اللغة والدراسات العليا.
`,
  `العمل والتنسيق مع الجهاز الوطني للاعتماد الأكاديمي وضمان جودة التعليم على زيادة عدد الجامعات وكليات المجتمع المعتمدة في الولايات المتحدة الأمريكية.
`,
  `المطالبة بزيادة التخصصات المدرجة في خطة الابتعاث السنوية لوزارة التعليم العالي.
`,
  `اضافة نظام بعثة المرافق (الزوج أو الزوجة) على نفقة وزارة التعليم العالي لكافة المبتعثين من الجهات المختلفة ليتسنى للمرافق إتمام درجة علمية واحدة.
`,
  `مساواة مخصصات مبتعثي جامعة الكويت والهيئة العامة للتعليم التطبيقي والتدريب ومعهد الكويت للأبحاث العلمية والمطالبة بتثبيت سعر الصرف كحال الطلبة المبتعثين على وزارة التعليم العالي.
`,
  `المطالبة بزيادة المخصصات المالية الشهرية للطلبة المبتعثين على نفقة ديوان الخدمة المدنية وتعديل نظام الصرف لهم.
`,
  `التنسيق مع جمعية أعضاء هيئة التدريس بجامعة الكويت لزيادة سن الابتعاث بجامعة الكويت إلى 40 سنة.
`,
  `مطالبة الأمانة العامة لمجلس الأمة استحداث بعثات مستقلة ماجستير ودكتوراه في العلوم السياسية ودراسات حل النزاعات والسلام.
`,
  `مطالبة بنك الكويت المركزي إلغاء شرط السن لبرنامج كفاءة.
`,
  `مطالبة الهيئة العامة للاستثمار إضافة تخصصات الاقتصاد والتمويل وعدم الاقتصار على تخصص ادارة الاعمال.
`,
  `مطالبة مؤسسة الكويت للتقدم العلمي استحداث بعثة الماجستير.
`,
  `المطالبة بصرف بدل المعدات لطلبة هندسة المعمار وطلبة الطب.
`,
  `المطالبة بتوفير فرص عمل للتدريب الميداني للطلبة في السفارة والقنصليات العامة والدائمة لطلبة العلوم السياسية.
`,
  `توفير دورات تدريبية وورش للطلبة بالتعاون مع مختلف الهيئات والمؤسسات التعليمية والتدريبية في الكويت.
`,
  `عمل دورات إرشادية لطلبة الدراسات العليا.
`,
  `المطالبة بمساواة طلبة درجة الماجستير على نفقة وزارة التعليم العالي بأقرانهم طلبة درجة البكالوريوس بجميع بمستحقاتهم.
`,
  `تغيير آلية صرف بدل التفوق للطلبة المبتعثين على نفقة وزارة التعليم العالي لتصرف للطلبة من قبل الملاحق الثقافية عوضاً من أن تصرف من قبل الوزارة في الكويت.
`,
];

export const nationalistVision = [
  `  الدفاع عن حرية الرأي والمعتقد والأسس الديمقراطية وتعزيز المفاهيم الوطنية.
`,
  `  توعية الطلبة والطالبات بأهمية المشاركة في الشأن العام وتبيان أهمية دورهم الفاعل بالمشاركة في العمل الديمقراطي. 
`,
  `  مناهضة التعصب والتمييز بشتى أشكاله والعمل على بث روح التآلف والوطنية بين الطلبة.
`,
  `  الاستمرار بدعم القضايا ذات الصلة بوحدة المصير ونصرة القضية الفلسطينية.
`,
  `  العمل على تنظيم ندوات سياسية وثقافية لتوعية الطلبة بأهمية العمل الوطني المؤسسي.
`,
];

export const activistVision = `دائما ما كانت قائمة الوحدة الطلابية مهتمةً بتصحيح مسار العملية الانتخابية في الولايات المتحدة الأمريكية، ولما شاب العملية الإنتخابية في السنوات القليلة السابقة من تدخلات من قبل الهيئة التنفيذية في الكويت، وعمليات أبطال وتخريب متعمد من بعض القوائم فإن الوحدة الطلابية ستحمل على عاتقها ملف إشهار الإتحاد الوطني لطلبة الكويت - فرع الولايات المتحدة الأمريكية في الكويت إستكمالاً لعملية إشهاره في الولايات المتحدة الأمريكية الذي كان بعهد الوحدة الطلابية منذ استلامها لدفة الإتحاد في 2002. وتأتي هذه المطالبة من منطلق زيادة الشفافية لكيان الاتحاد أمام جموع الطلبة والسماح لهم بمحاسبة الإتحاد ومراقبة أعماله، بالإضافة الى تثبيت كيان الإتحاد كجهة معترف بها أمام المؤسسات والهيئات العامة والخاصة في الكويت ويتمتع باستقلالية كاملة تحت الأطر القانونية الكاملة في الكويت وفي الولايات المتحدة الأمريكية على حد سواء.
`;

export const nuksRole = [
  `تطمح قائمة الوحدة الطلابية منذ تأسيسها الى يومنا هذا بالعمل على تطوير مفهوم العمل النقابي عن طريق تقديم مفهوم جديد للإتحاد الوطني لطلبة الكويت - فرع الولايات المتحدة الأمريكية، فالوحدة الطلابية تتعهد بالاستمرار في العمل والإنجاز بكل ما فيه مصلحة الطلبة والطالبات الدارسين في الولايات المتحدة الأمريكية وتوفير كل ماهو من شأنه توفير سبل النجاح لهم في الخارج منذ لحظة قرارهم بالدراسة إلى يوم تخرجهم وعودتهم إلى الكويت. 
`,
  `فالإتحاد تحت راية قائمة الوحدة الطلابية سيستمر بالمطالبة في تعديل وتحسين أداء الملاحق الثقافية وضمان أفضل الخدمات التي تلبي طموحهم من قبل الملاحق والوزارة. وتتعهد قائمة الوحدة الطلابية بالمطالبة والسعي الدؤوب لتذليل جميع العقبات والعراقيل التي يواجهها الطالب في الخارج عن طريق الضغط على الجهات المعنية بزيادة المخصصات الشهرية للطلبة والاعتراف بمزيد من الجامعات والتخصصات لهذا العام.
`,
  `وفي الجانب الإجتماعي فستعمل الوحدة الطلابية بقيادتها للإتحاد على توسيع رقعة الأنشطة لتشمل جميع الولايات التي يتواجد بها الطلبة والطالبات، والعمل على القيام بأنشطة بشكل دوري تلبي رغباتهم وتناسب طموحاتهم بشتى المجالات. بالإضافة الى ذلك سيعمل الاتحاد تحت راية قائمة الوحدة الطلابية على توفير فرص عمل وفرص تدريب ميداني (NUKS Careers & NUKS Internships) لهم في الكويت وفي الولايات المتحدة الأمريكية وذلك من منطلق إعدادهم لسوق العمل في الكويت.
`,
  `وتتعهد قائمة الوحدة الطلابية بإلتزامها التام بجميع مبادئها الأربع (الحرية والعدالة والمساواة والديمقراطية) بأي قرار تتخذه متمسكةً بهذه المبادئ التي نؤمن بسلامتها منذ تأسيس قائمة الوحدة الطلابية لتكون مرجعا وميزان عدل نعتد به بكل القضايا، إذ تخوض قائمة الوحدة الطلابية هذه الإنتخابات تحت شعار باقون بكم وهو العهد الذي يجمع القائمة مع مؤيديها منذ تأسيسها الى يومنا هذا ولتستمر نموذجا للعمل الوطني الطلابي في الكويت وخارجها.
`,
];
