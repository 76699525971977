import React from "react";
import styled from "styled-components";
import mainIcon from "../images/main.svg";
import visionIcon from "../images/vision.svg";
import candidatesIcon from "../images/candidates.svg";
import logo from "../images/alwihdaIcon.svg";
import nuksLogo from "../images/nuks-logo.svg";
import story from "../images/story.png"
import target from "../images/target.png"
import media from "../utils/media";
const StyledNav = styled.nav`
  z-index: 9999;
  position: fixed;
  width: 100%;
  height: 75px;
  top: 0;
  right: 0;
  background: black;
  ${media.tablet`
  width: 250px;
  height: 100vh;
  top:0;
  right: 0;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
`}
  ul {
    padding: 20px;
    width: 100%;
    overflow: scroll;
    height: 75px;
    display: flex;
    flex-flow: row nowrap;
    white-space: nowrap;
    align-items: center;
    justify-content: flex-start;
    list-style: none;
    gap: 40px;
    ${media.tablet`
    height: auto;
    overflow: hidden;
    padding: 10px;
    height: auto;
    display: flex;
    flex-flow: column wrap;
    gap: 10px;
`}
  }
  li {
    margin-inline-end: 30px;
    width: 100%;
    text-align: center;
    ${media.tablet`
    margin-bottom: 30px;
  `}
    a {
      font-size: 1.3em;
      text-decoration: none;
      color: #fff;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 10px;
      ${media.tablet`
      display: flex;
      flex-flow: row wrap;
      height: inherit;
      font-size: 1.8em;
      text-decoration: none;
      color: #fff;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 20px;
      margin-inline-start: 10px;
    `}
      img {
        height: 30px;
      }
    }
  }
`;

function Navigation() {
  return (
    <StyledNav>
      <ul>
        <li>
          <a href="#main">
            <img src={mainIcon} alt="" />
            <span>باقون بكم</span>
          </a>
        </li>
        <li>
          <a href="#about">
            <img src={logo} alt="" />
            <span>الوحدة الطلابية</span>
          </a>
        </li>
        <li>
          <a href="/story">
            <img src={story} alt="" />
            <span>قصتنا</span>
          </a>
        </li>
        <li>
          <a href="/about">
            <img src={target} alt="" />
            <span>رؤيتنا وأهدافنا</span>
          </a>
        </li>
        <li>
          <a href="#vision">
            <img src={visionIcon} alt="" />
            <span>الرؤية الانتخابية</span>
          </a>
        </li>
        <li>
          <a href="#achievements">
            <img src={nuksLogo} alt="" />
            <span>الانجازات</span>
          </a>
        </li>
        <li>
          <a href="#leadership">
            <img src={candidatesIcon} alt="" />
            <span>التنسيق</span>
          </a>
        </li>
      </ul>
    </StyledNav>
  );
}

export default Navigation;
