import React from "react";
import styled from "styled-components";
import backgroundImage from "../../images/elections-image-2.jpg";
import { data } from "./data";
const StoryContainer = styled.div`
  width: 100%;
  #story-header {
    width: 100%;
    height: 200px;
    background-image: url(${backgroundImage});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat no-repeat;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    h2 {
      display: block;
      background: #ed1c24;
      padding: 10px;
      color: white;
      font-size: 2em;
      font-weight: bold;
    }
  }
  .story-section {
    padding: 20px;
    h3 {
      font-size: 1.4em;
      font-weight: bold;
      padding: 15px 0;
    }
    ul {
      list-style-type: circle;
    }
    li {
      margin-inline-start: 20px;
    }
    p,
    li {
      font-size: 1.2em;
      line-height: 1.4em;
      text-align: justify;
    }
    p {
      margin-block-end: 15px;
    }
  }
`;
function Story() {
  return (
    <StoryContainer>
      <div id="story-header">
        <h2>قصتنا</h2>
      </div>
      {data.map((item , i) => (
        <div className="story-section" key={i}>
          <h3>{item.title}</h3>
          <div dangerouslySetInnerHTML={{ __html: item.content }} />
          <ul>
            {item.team.map((teamMember , i) => (
              <li key={i}>{teamMember}</li>
            ))}
          </ul>
        </div>
      ))}
    </StoryContainer>
  );
}

export default Story;
