import React from "react";
import styled from "styled-components";
import backgroundImage from "../../images/elections-image-2.jpg";
const AboutContainer = styled.div`
  width: 100%;
  #about-header {
    width: 100%;
    height: 200px;
    background-image: url(${backgroundImage});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat no-repeat;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    h2 {
      display: block;
      background: #ed1c24;
      padding: 10px;
      color: white;
      font-size: 2em;
      font-weight: bold;
    }
  }
  .about-section {
    padding: 20px;
    h4 {
      font-size: 1.2em;
      font-weight: bold;
      margin-block: 10px;
    }
    h3 {
      font-size: 1.4em;
      font-weight: bold;
      padding: 15px 0;
    }
    ul {
      list-style-type: circle;
    }
    li {
      margin-inline-start: 20px;
    }
    p,
    li {
      font-size: 1.2em;
      line-height: 1.4em;
      text-align: justify;
    }
  }
`;
function About() {
  return (
    <AboutContainer>
      <div id="about-header">
        <h2>رؤيتنا وأهدافنا</h2>
      </div>
      <div className="about-section">
        <h3>رؤيتنا</h3>
        <p>
          قائمة الوحدة الطلابية هي قائمة طلابية تخوض انتخابات الاتحاد الوطني
          لطلبة الكويت - فرع الولايات المتحدة الأمريكية ، أنشئت في صيف عام 1999
          على أيدي بعض الطلبة الذين كانوا يفتقدوا لتجمع يلم شمل الحركة الطلابية
          في الولايات المتحدة الأمريكية والتي كان يغلب عليها النفس الطائفي
          والقبلي والحزبي، فأتى تصور لهذا التجمع فتم وضع مبادئ عامة كالتالي:
          تكريس الديمقراطية وتعدد الآراء كأسلوب قي العمل- الإيمان بكافة مواد
          دستور دولة الكويت الصادر بعام 1962م- الإيمان بأهمية دور الطالب في بناء
          المستقبل - الاتحاد الوطني لطلبة الكويت هو الممثل الشرعي للطلبة -
          الطلبة سواسية لا فرف بينهم في الجنس و الأصل أو النسب أو الطائفة. وتم
          تحديد تلك المبادئ بإطار عام ارتكز على أربعة مبادئ وهي العدالة والحرية
          والمساواة والديمقراطية. وتم اختيار اسم "الوحدة الطلابية" ليعبر عن أساس
          تلك المبادئ وهو توحيد الصف الطلابي في الولايات المتحدة الامريكية تحت
          اطار الوحدة الوطنية
        </p>
      </div>
      <div className="about-section">
        <h3>مبادئنا</h3>
        <p>
          قائمة الوحدة الطلابية هي قائمة طلابية تخوض انتخابات الاتحاد الوطني
          لطلبة الكويت - فرع الولايات المتحدة الأمريكية ، أنشئت في صيف عام 1999
          على أيدي بعض الطلبة الذين كانوا يفتقدوا لتجمع يلم شمل الحركة الطلابية
          في الولايات المتحدة الأمريكية والتي كان يغلب عليها النفس الطائفي
          والقبلي والحزبي، فأتى تصور لهذا التجمع فتم وضع مبادئ عامة كالتالي:
          تكريس الديمقراطية وتعدد الآراء كأسلوب قي العمل- الإيمان بكافة مواد
          دستور دولة الكويت الصادر بعام 1962م- الإيمان بأهمية دور الطالب في بناء
          المستقبل - الاتحاد الوطني لطلبة الكويت هو الممثل الشرعي للطلبة -
          الطلبة سواسية لا فرف بينهم في الجنس و الأصل أو النسب أو الطائفة. وتم
          تحديد تلك المبادئ بإطار عام ارتكز على أربعة مبادئ وهي العدالة والحرية
          والمساواة والديمقراطية. وتم اختيار اسم "الوحدة الطلابية" ليعبر عن أساس
          تلك المبادئ وهو توحيد الصف الطلابي في الولايات المتحدة الامريكية تحت
          اطار الوحدة الوطنية.
        </p>
      </div>
      <div className="about-section">
        <h3>المباديء الأساسية</h3>
        <ul>
          <li>تكريس الديمقراطية و تعدد الآراء كأساس للعمل</li>
          <li>الإيمان بكافة مواد الدستور 1962</li>
          <li>الإيمان بأهمية دور الطالب في بناء المستقبل</li>
          <li> الاتحاد الوطني لطلبة الكويت هو الممثل الشرعي للطلبة</li>
          <li>
            الطلبة سواسية لا فرق بينهم في الجنس أو الأصل أو النسب أو الطائفة
          </li>
        </ul>
      </div>
      <div className="about-section">
        <h3>المبادئ</h3>
        <h4>الديموقراطية</h4>
        <p>
          وتعني –بصفة عامة- حكم الأغلبية مع إحترام رأي الأقلية في المعارضة، ويعد
          مبدأ الديموقراطية نهج القائمة في العمل والتعامل مع الآخرين والذي يوضح
          آلية العمل حيث تتخذ القرارات بالتصويت فيما بين أعضاء هيئة التنسيق
          العامة للقائمة المنتخبين المنتخبين وأعضاء اللجان وأعضاء الجمعية
          العمومية للقائمة. وتعتمد القرارات الحائزة على أغلبية الأعضاء دون
          مصادرة حق الأقلية في إبداء الرأي المخالف ودون أن يشكل ذلك أي حساسية
          فيما بين أعضاءه.
        </p>
        <h4>المساواة</h4>
        <p>
          وتعني –بصفة عامة- مساواة الطلبة في الحقوق والتكاليف والواجبات، وإعطاء
          كل طالب أقصى ما يمكن من الفرص المتماثلة التي يتمتع بها أي طالب آخر،
          وتناهض القائمة أي شكل من أشكال التمييز والتفرقة سواء كان بسبب العنصر
          أو اللون أو الجنس أو الرأي السياسي أو الأصل الوطني أو الإجتماعي أو أي
          وضع آخر على أساس من إحترام آدمية الإنسان والكرامة البشرية.
        </p>
        <h4>الحرية</h4>
        <p>
          وتعني –بصفة عامة- شعور الطالب بكيانه ومقدرته على فعل مالا يضر بغيره من
          الطلبة دون التعرض للحق العمومي ضمن المباديء الإسلامية. وهذه الحرية
          كائنة لكل شخص بمحض وجوده، لا يحق للغير التعرض لها أو حرمانه منها،
          والحرية لها أوجه متعددة كالحرية الشخصية وحرية الرأي والبحث العلمي
          وحرية الطباعة والنشر وحرية تكوين الجمعيات والنقابات وحرية الترشيح
          وإنتخاب من يمثله. والحرية الحقة هي وسط عدل دون تطرف.
        </p>
        <h4>العدالة</h4>
        <p>
          وتعني –بصفة عامة- حق جميع الطلبة بمعاملة عادلة ومماثلة على أساس
          المساواة وعدم التمييز كركيزة أساسية من ركائز الديموقراطية.
        </p>
      </div>
    </AboutContainer>
  );
}

export default About;
