import React, { useEffect, useLayoutEffect, useState } from "react";
import styled from "styled-components";
import logo from "../../images/alwihda-full-logo.svg";
import backgroundImage from "../../images/background-la.jpg";
import newbackground from "../../images/newbackground.jpg"
import slogan from "../../images/slogan.svg";
import media from "../../utils/media";

const Section = styled.div`
  background: red;
  width: 100%;
  height: 55vh;
  overflow: hidden;
  background-image: url(${newbackground});
  background-repeat: no-repeat no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-flow: column nowrap;
  ${media.tablet`
  background-size: 100% auto;
  background-position: 50% 30%;
`}
  #main-slogan {
    flex: 4;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    img {
      width: 200px;
      height: auto;
      ${media.tablet`
       height: 500px;
       width: auto;
  `}
    }
  }
  #main-logo {
    flex: 2;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    img {
      width: 100px;
      height: auto;
      ${media.tablet`
      height: 200px;
      width: auto;
 `}
    }
  }
`;

function FirstFold() {
  useLayoutEffect(() => {
    function handleResize() {
      const el = document.getElementById("main");
      el.style.height = window.clientHeight + "px";
      el.style.width = window.clientWidth + "px";
    }
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <Section id="main">
      <div id="main-slogan">

      </div>
    </Section>
  );
}

export default FirstFold;
