import React from "react";
import styled from "styled-components";
import media from "../../utils/media";
import { achievements } from "./achievements-data";
const Section = styled.section`
  padding: 20px;
  h2 {
    font-size: 2em;
    font-weight: bold;
    display: block;
    text-align: center;
    padding: 10px;
  }
  .achievements-highlight {
    color: #ed1c24;
    font-weight: bold;
  }
  h3 {
    font-size: 1.5em;
    font-weight: bold;
    text-align: center;
    text-decoration: underline;
    padding: 10px;
  }
  p {
    font-size: 1.2em;
    line-height: 1.6em;
    text-align: justify;
    margin-block-end: 20px;
  }
  #achievements-wrapper {
    ${media.tablet`
      display: flex;
      flex-flow: row wrap;
      align-content: center;
      .achievements-section {
        width: 100%;
        height: auto;
      }
    `}
  }
`;
function Achievements() {
  return (
    <Section id="achievements">
      <div id="achievements-preamble">
        <h2>الانجازات</h2>
        <p>
          تتقدم إليكم قائمة الوحدة الطلابية بسرد تاريخي لما تم اتخاذه من مواقف
          لقضايا وطنية وما تم تحقيقه من مكتسبات طلابية تمت بفضل الله تعالى ووقفة
          الجموع الطلابية التي أولت الوحدة الطلابية ثقتها بإدارة الإتحاد الوطني
          لطلبة الكويت - فرع الولايات المتحدة الأمريكية - عبر السنين منذ عام
          2002. إذ تعتبر هذه الإنجازات وفاءً للعهد بينكم وبين قائمتكم. فمن منطلق
          إيماننا بأنكم المرجع الذي نحتكم إليه حرّيٌ على قائمة الوحدة الطلابية
          الوفاء بالعهد مع الجموع الطلابية الذي يقتضي بالعمل والإنجاز لما تقتضيه
          مصلحة الطلبة. ومن هنا نضع بين أيديكم سرداً تاريخيا مفصلا لأهم ما قامت
          به قائمة الوحدة الطلابية من إنجازات بناءً على رغبة الجموع الطلابية عبر
          السنين.
        </p>
        <div id="achievements-wrapper">
          {achievements.map((item , i) => (
            <div
            key={i}
              className={`achievements-section ${
                item.highlight && "achievements-highlight"
              }`}
            >
              <h3>{item.title}</h3>
              <p>{item.content}</p>
            </div>
          ))}
        </div>
      </div>
    </Section>
  );
}

export default Achievements;
