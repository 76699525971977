import { createGlobalStyle } from "styled-components";
import DubaiW23LightEot from "../fonts/DubaiW23-Light.eot";
import DubaiW23LightTtf from "../fonts/DubaiW23-Light.ttf";
import DubaiW23LightWoff from "../fonts/DubaiW23-Light.woff";
import DubaiW23LightWoff2 from "../fonts/DubaiW23-Light.woff2";
import DubaiW23RegularEot from "../fonts/DubaiW23-Regular.eot";
import DubaiW23RegularTtf from "../fonts/DubaiW23-Regular.ttf";
import DubaiW23RegularWoff from "../fonts/DubaiW23-Regular.woff";
import DubaiW23RegularWoff2 from "../fonts/DubaiW23-Regular.woff2";
import DubaiW23BoldEot from "../fonts/DubaiW23-Bold.eot";
import DubaiW23BoldTtf from "../fonts/DubaiW23-Bold.ttf";
import DubaiW23BoldWoff from "../fonts/DubaiW23-Bold.woff";
import DubaiW23BoldWoff2 from "../fonts/DubaiW23-Bold.woff2";
import DenLightBlack from "../fonts/din-next-lt-w23-black.ttf";
import DenLightLight from "../fonts/din-next-lt-arabic-light.ttf";

export const themeColors = {
  blue: "#005dbc",
  paleBlue: "#2460ac",
  lightOrange: "#fcb41e",
  darkOrange: "#ff5531",
  grey: "#3b3b3c",
  purple: "#4c002e",
};

const GlobalStyle = createGlobalStyle`
*, html {
    font-family: "Dubai", "SomeArabic";
}

@font-face {
    font-family: "SomeArabic";
    src: local("SomeArabic"),
      url(${DenLightLight}) format("ttf");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "SomeArabic";
    src: local("SomeArabic"),
      url(${DenLightBlack}) format("ttf");
    font-weight: bold;
    font-style: normal;
  }
@font-face {
    font-family: 'Dubai';
    src: local('Dubai'), local('Dubai'),
    url(${DubaiW23LightEot}) format('eot'),
    url(${DubaiW23LightTtf}) format('ttf'),
    url(${DubaiW23LightWoff}) format('woff');
    url(${DubaiW23LightWoff2}) format('woff2');
    font-weight: 100;
    font-style: lighter;
}

@font-face {
    font-family: 'Dubai';
    src: local('Dubai'), local('Dubai'),
    url(${DubaiW23RegularEot}) format('eot'),
    url(${DubaiW23RegularTtf}) format('ttf'),
    url(${DubaiW23RegularWoff}) format('woff');
    url(${DubaiW23RegularWoff2}) format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Dubai';
    src: local('Dubai'), local('Dubai'),
    url(${DubaiW23BoldEot}) format('eot'),
    url(${DubaiW23BoldTtf}) format('ttf'),
    url(${DubaiW23BoldWoff}) format('woff');
    url(${DubaiW23BoldWoff2}) format('woff2');
    font-weight: 700;
    font-style: bold;
}

`;
export default GlobalStyle;
