import React from "react";
import FirstFold from "./FirstFold";
import Vision from "./Vision";
import Leadership from "./Leadership";
import About from "./About";
import styled from "styled-components";
import Navigation from "../../components/Navigation";
import media from "../../utils/media";
import Achievements from "./Achievements";
const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  ${media.tablet`
    margin-top: 0;
      width: calc(100% - 250px);
      float: left;
    `}
`;
function Main() {
  return (
    <Container>
      <Navigation />
      <FirstFold />
      <About />
      <Vision />
      <Achievements />
      <Leadership />
    </Container>
  );
}

export default Main;
