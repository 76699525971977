import salemAlturkiImage from "../../images/current-leadership/salem-alturki.jpg";
import abdullahalmansour from "../../images/current-leadership/abdullah-almansour.JPG"
import khalidmandani from "../../images/current-leadership/khaled-mandani.jpg"
import reemalraish from "../../images/current-leadership/reem-alraish.jpg"
import abdullahalrashid from "../../images/current-leadership/abdullah-alrashid.jpg"
import fahadboajarid from "../../images/current-leadership/fahad-aboajarid.jpg"
import yaraalkandari from "../../images/current-leadership/yara-alkandari.jpg"
import rashidalmdahkah from "../../images/current-leadership/rashid-almodahkah.png"
import janaaljassim from "../../images/current-leadership/jana-aljassim.jpg"
import yaqoubashkanani from "../../images/current-leadership/yaqoub-ashkanani.jpg"
import omarbushahri from "../../images/current-leadership/omar-boshihri.jpeg"

export const currentLeadership = [
  {
    id: 1,
    order: 0,
    name: "عبدالله عبدالعزيز المنصور",
    title: "المنسق العام",
    avatar: abdullahalmansour,
    university: "University of Arizona",
    major: "هندسة صناعية",
  },
  {
    id: 2,
    order: 1,
    name: "سالم محمد التركي",
    title: "نائب المنسق العام",
    avatar: salemAlturkiImage,
    university: "University of Colorado - Denver",
    major: "هندسة مدنية",
  },
  {
    id: 3,
    order: 2,
    name: "خالد مصعب مندني",
    title: "أمين السر",
    avatar: khalidmandani,
    university: "California State University-Bakersfield",
    major: "محاسبة",
  },
  {
    id: 4,
    order: 3,
    name: "ريم فارس الريش",
    title: "أمين الصندوق",
    avatar: reemalraish,
    university: "University of Miami",
    major: "علوم البحار والأحياء",
  },
  {
    id: 5,
    order: 4,
    name: "عبدالله عبدالرزاق ال رشيد",
    title: "رئيس اللجنة الأكاديميه",
    avatar: abdullahalrashid,
    university: "University of Dayton",
    major: "هندسة كهربائية",
  },
  {
    id: 6,
    order: 5,
    name: "فهد طلال بوعجاريد",
    title: "رئيس اللجنة الثقافية",
    avatar: fahadboajarid,
    university: "Lawrence Technological University",
    major: "هندسة كهربائية",
  },
  {
    id: 7,
    order: 6,
    name: "يارا بدر الكندري",
    title: "رئيس اللجنة الطبية",
    avatar: yaraalkandari,
    university: "Virgina Commonwealth Univeristy",
    major: "طب بشري",
  },
  {
    id: 8,
    order: 7,
    name: "راشد يوسف المضاحكة",
    title: "رئيس اللجنة الاعلامية",
    avatar: rashidalmdahkah,
    university: "Pennsylvania State Univeristy",
    major: "امن سبراني",
  },
  {
    id: 9,
    order: 8,
    name: "جنى عمرو الجاسم",
    title: "رئيس لجنة Careers & Internships",
    avatar: janaaljassim,
    university: "University of Colorado-Boulder",
    major: "علاقات دولية",
  },
  {
    id: 10,
    order: 9,
    name: "يعقوب محمد اشكناني",
    title: "رئيس اللجنة الاجتماعية و الرياضية",
    avatar: yaqoubashkanani,
    university: "Oklahoma State University",
    major: "هندسة معمارية",
  },
  {
    id: 11,
    order: 10,
    name: "عمر فؤاد بوشهري",
    title: "رئيس مجلة الوحدة",
    avatar: omarbushahri,
    university: "University of Colorado-Denver",
    major: "هندسة مدنية",
  },
];
