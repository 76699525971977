import React from "react";
import styled from "styled-components";
import { currentLeadership } from "./current-leadership";
import media from "../../utils/media";
const Div = styled.section`
  padding: 10px;
  width: 100%;
  ${media.tablet`
  width: 70%;
  margin-inline: auto;
  `}
  #leadership-title {
    font-size: 2em;
    font-weight: bold;
    display: block;
    text-align: center;
    background-color: #ed1c24;
    padding: 10px;
    color: #fff;
  }
  .leadership-wrapper {
    padding: 20px;
    width: 100%;
    overflow-y: scroll;
    display: flex;
    flex-flow: row nowrap;
    white-space: nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    list-style: none;
    gap: 20px;
    ${media.tablet`
    flex-flow: row wrap;
    white-space: initial;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    `}
    .leadership-card {
      text-align: center;
      width: 300px;
      ${media.tablet`
      width: 30%;
      `}
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      transition: 0.3s;
      border-radius: 5px;
      .leadership-card-info {
        text-align: center;
        width: 300px;
        white-space: initial;
        padding: 2px 16px;
        ${media.tablet`
        width: 100%;
        `}
      }
      * {
        margin-block-end: 10px;
      }
      img {
        border-radius: 5px 5px 0 0;
        height: 350px;
        width: 350px;
        object-fit: fill;
      }
      h3 {
        font-size: 1.4em;
        font-weight: bold;
      }
      h4 {
        font-size: 1.2em;
      }
      p {
        font-size: 1.2em;
        line-height: 1em;
      }
    }
  }
`;
function Leadership() {
  return (
    <Div id="leadership">
      <h2 id="leadership-title">تنسيق 2023</h2>
      <div className="leadership-wrapper">
        {currentLeadership.map((teamMember , i) => (
          <div
            key={i}
            className="leadership-card"
          >
            <img src={teamMember.avatar} alt="" />
            <div className="leadership-card-info">
              <h3>{teamMember.name}</h3>
              <h4>{teamMember.title}</h4>
              <p>{teamMember.university}</p>
              <p>{teamMember.major}</p>
            </div>
          </div>
        ))}
      </div>
    </Div>
  );
}

export default Leadership;
